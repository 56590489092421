import React from 'react';

const OrientaCta = () => {

    return (
        <div className='bg-primary'>
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col-12">
                        {/* <h2 className="h3 fw-bold mb-5 text-white">Orienta il tuo futuro</h2> */}
                        <h3 className="h2 mb-4 mb-md-5 text-center text-white fw-normal">
                            Orienta il tuo futuro <br />
                            Scopri <span className='fw-bold'>chi sei.</span>
                        </h3>
                        <p className='fs-4 lh-base text-center text-white'>
                            Conosci i tuoi valori,<br className='d-none d-md-block' />
                            le tue attitudini, le tue competenze.
                        </p>
                        <div className='d-flex align-items-center justify-content-center mt-4 mt-md-5'>
                            <a className='btn btn-white rounded-0 fs-5' href='/orientagame'>
                                Gioca a <br />
                                ORIENTA<span className='fw-700'>GAME</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrientaCta;