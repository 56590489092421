import React from 'react';
import { VictoryPie } from 'victory';

const PieProgressGraph = ({ percent, classes }) => {
    const customClasses = classes ? classes : '';

    return (
        <div className={`pie-graph mx-auto ${customClasses}`}>
            <p className='text-center position-absolute z-2 percentage fs-4 fw-bold m-0'>
                {percent}%
            </p>
            <VictoryPie
                padAngle={0}
                // used to hide labels
                labelComponent={<span />}
                innerRadius={70}
                width={300} height={300}
                data={[{ 'key': "", 'y': percent }, { 'key': "", 'y': (100 - percent) }]}
                colorScale={["#0066cc", "#e6e6e6"]}
            />
        </div>
    );
}

export default PieProgressGraph;