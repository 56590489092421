import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Professioni from './pages/Professioni.jsx';
import PercorsiDiStudio from './pages/PercorsiDiStudio.jsx';
import DettaglioProfessione from './pages/DettaglioProfessione.jsx';
import DettaglioPercorso from './pages/DettaglioPercorso.jsx';
import DettaglioSkill from './pages/DettaglioSkill.jsx';
import DettaglioSector from './pages/DettaglioSector.jsx';
import Home from './pages/Home.jsx';
import Jobs from '../data/jobs.json';
import Fields from '../data/fields.json';
import Skills from '../data/skills.json';
import Sectors from '../data/sectors.json';

const jobs = Jobs;
const fields = Fields;
const skills = Skills;
const sectors = Sectors;

function App() {

  useEffect(() => {
    const reactRoot = document.getElementById('react-root');
    const oldContainer = document.querySelector('.neutral-2-bg');

    if (reactRoot) {
      const breadcrumbContainer = document.querySelector('.breadcrumb-container')?.closest('.container');
      if (breadcrumbContainer) {
        breadcrumbContainer.after(reactRoot);
        setTimeout(() => {
          oldContainer?.remove();
        }, 500);
      }

      const removeIfReact = document.querySelector('.remove-if-react');
      if (removeIfReact) {
        setTimeout(() => {
          removeIfReact?.remove();
        }, 500);
      }
    }
  }, []);


  return (
    <BrowserRouter>
      <Routes>s
        {/* Professioni ----------------------------------------------------- */}
        <Route path="/professioni/" element={<Professioni />} />
        {jobs.map((job) => (
          <Route
            key={job.slug}
            path={`/professioni/${job.slug}`}
            element={<DettaglioProfessione selectedJob={jobs.find((j) => j.slug === job.slug)} />}
          />
        ))}
        {skills.map((skill) => (
          <Route
            key={skill.slug}
            path={`/professioni/competenze/${skill.slug}`}
            element={<DettaglioSkill selectedSkill={skills.find((s) => s.slug === skill.slug)} />}
          />
        ))}
        {sectors.map((sector) => (
          <Route
            key={sector.slug}
            path={`/professioni/settori/${sector.slug}`}
            element={<DettaglioSector selectedSector={sectors.find((sec) => sec.slug === sector.slug)} />}
          />
        ))}


        {/* Percorsi di studio -----------------------------------------------*/}
        <Route path="/percorsi-di-studio/" element={<PercorsiDiStudio />} />
        {fields.map((field) => (
          <Route
            key={field.slug}
            path={`/percorsi-di-studio/${field.slug}`}
            element={<DettaglioPercorso selectedField={fields.find((selected) => selected.slug === field.slug)} />}
          />
        ))}


        {/* Home ----------------------------------------------------------- */}
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
