import React from 'react';

const ProgressLine = ({ percent, classes }) => {
    const customClasses = classes ? classes : '';

    return (
        <div className={`${customClasses}`}>
            <div className="progress rounded-2">
                <div
                    className="progress-bar text-uppercase fw-bold bg-primary fs-6"
                    role="progressbar"
                    style={{ minWidth: "20%", width: `${percent}%` }}
                    aria-valuenow={`${percent}`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                >
                    {percent < 30 ? 'bassa' : percent < 70 ? 'media' : percent < 80 ? 'medio-alta' : 'alta'}
                </div>
            </div>
        </div>
    );
};

export default ProgressLine;