import React from 'react';
import Navigation from '../components/Navigation.jsx';

function Home() {
    return (
        <div>
            <Navigation />
        </div>
    );
}

export default Home;
