import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Jobs from '../../data/jobs.json';
import Skills from '../../data/skills.json';
import Sectors from '../../data/sectors.json';

const data = Jobs;
const skillsData = Skills;
const sectorsData = Sectors.sort((a, b) => a.label.localeCompare(b.label));

function Professioni() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [lastValidSearch, setLastValidSearch] = useState([]);
    const [resultsNumber, setResultsNumber] = useState(5);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = data
            .filter(item => item.jobName.toLowerCase().includes(value) || item.description.toLowerCase().includes(value))
            .slice(0, resultsNumber);

        if (filtered.length > 0) {
            setFilteredJobs(filtered);
            setLastValidSearch(filtered);
        } else if (searchTerm && filtered.length === 0) {
            setFilteredJobs(lastValidSearch);
        } else {
            setFilteredJobs([]);
        }
    };

    const renderAllResults = () => {
        setResultsNumber(20);
    };

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredJobs([]);
            setLastValidSearch([]);
            setResultsNumber(5);
        }
    }, [searchTerm, resultsNumber]);

    useEffect(() => {
        if (searchTerm !== '') {
            handleSearch({ target: { value: searchTerm } });
        }
    }, [resultsNumber]);

    const renderSearch = () => (
        <div className='container py-5 my-5'>
            <div className='row'>
                <div className='col-12'>
                    <h1 className='bd-title fw-bold text-black'>Cerca una professione</h1>
                </div>
                <div className='col-12 mt-5'>
                    <div className="form-group mb-0">
                        <div className="input-group">
                            {searchTerm === '' && (
                                <label htmlFor="input-group-3">Quale professione stai cercando?</label>
                            )}
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="input-group-3"
                                name="input-group-3"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-primary radius-0" type="button" id="button-3" onClick={renderAllResults}>CERCA</button>
                            </div>
                        </div>
                    </div>
                    {filteredJobs.length > 0 && (
                        <div className='d-flex flex-column align-items-start justify-content-start p-4 ps-3 '>
                            {filteredJobs.map((job, index) => (
                                <Link
                                    key={index}
                                    className='text-decoration-none w-100 d-flex align-items-center mb-4 fw-semibold'
                                    to={`/professioni/${job.slug}`}
                                >
                                    <p className='mb-0 fs-4 pe-4'>{job.jobName}</p>
                                    <Icon.ArrowRightShort size={40} className='text-primary mw-icon' />
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );


    return (
        <>
            {renderSearch()}
            <div className='container py-5 my-5'>
                <div className='row'>
                    <div className="col-12">
                        <h2 className="h3 fw-bold mb-3">Pensi di possedere una di queste competenze?</h2>
                        <p className='fs-4 lh-base mb-0'>
                            Esplora le professioni per cui le imprese le richiedono maggiormente, e lasciati ispirare...
                        </p>
                        <div className='mt-4 section-fields-wrapper'>
                            {skillsData.map((skill, index) => (
                                <Link
                                    key={index}
                                    to={`/professioni/competenze/${skill.slug}`}
                                    className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                    {skill.label}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5 my-5">
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="h3 fw-bold mb-4">Esplora i settori economico/professionali e le professioni che più li caratterizzano</h2>
                    </div>
                </div>
                <div className={`row justify-content-start d-flex align-items-stretch pt-5 pt-lg-0`}>
                    {sectorsData.map((sector, index) => (
                        <div className='col-12 col-lg-4 mb-5 mb-lg-4 mt-lg-4' key={index}>
                            <div className='card border-0 bg-100 h-100 rounded-3'>
                                <div className='card-body d-flex flex-column justify-content-between'>
                                    <div className='main'>
                                        <h3 className='h5 fw-bold mb-2'>{sector.label}</h3>
                                    </div>
                                    <div className='info'>
                                        <p className='mb-4'>
                                            {sector.description}
                                        </p>
                                    </div>
                                    <Link
                                        key={index}
                                        to={`/professioni/settori/${sector.slug}`}
                                        className='text-uppercase text-decoration-none text-primary fw-semibold'>
                                        Scopri di più
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Professioni;
