import React from 'react';
import { useEffect } from 'react';
import FieldsdData from '../../data/fields.json';
import { Link } from 'react-router-dom';

function PercorsiDiStudio() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var FieldTypes;
    (function (FieldTypes) {
        FieldTypes["PROFESSIONAL_TRAINING"] = "PROFESSIONAL_TRAINING";
        FieldTypes["HIGHSCHOOLS"] = "HIGHSCHOOLS";
        FieldTypes["TECHNICAL_INSTITUTES"] = "TECHNICAL_INSTITUTES";
        FieldTypes["COLLEGES_DEGREES"] = "COLLEGES_DEGREES";
        FieldTypes["ITS"] = "ITS";
    })(FieldTypes || (FieldTypes = {}));

    const proTrainingFields = FieldsdData
        .filter((field) => field.type === FieldTypes.PROFESSIONAL_TRAINING)
        .sort((a, b) => a.title.localeCompare(b.title));

    const highSchoolFields = FieldsdData
        .filter((field) => field.type === FieldTypes.HIGHSCHOOLS)
        .sort((a, b) => a.title.localeCompare(b.title));

    const technicalInstituteFields = FieldsdData
        .filter((field) => field.type === FieldTypes.TECHNICAL_INSTITUTES)
        .sort((a, b) => a.title.localeCompare(b.title));

    const collegeDegreesFields = FieldsdData
        .filter((field) => field.type === FieldTypes.COLLEGES_DEGREES)
        .sort((a, b) => a.title.localeCompare(b.title));

    const itsFields = FieldsdData
        .filter((field) => field.type === FieldTypes.ITS)
        .sort((a, b) => a.title.localeCompare(b.title));

    return (
        <div className='container py-5 my-5 '>
            <div className='row'>
                <div className='col-12'>
                    <h1 className='bd-title fw-bold text-black'>Esplora i percorsi di studio</h1>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-12 bg-200'>
                    <h2 className='h3 fw-semibold text-black text-uppercase my-1'>Diploma</h2>
                </div>
                <div className='col-12 mt-4 px-0'>
                    <div className="accordion" id="diploma-accordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="diploma-accordion-1">
                                <button className="accordion-button fs-4 rounded-0 collapsed px-3 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#diploma-collapse-1" aria-expanded="false" aria-controls="diploma-collapse-1">
                                    Istruzione e Formazione Professionale
                                </button>
                            </h2>
                            <div id="diploma-collapse-1" className="accordion-collapse collapse" data-bs-parent="#diploma-accordion" role="region" aria-labelledby="diploma-accordion-1">
                                <div className="accordion-body">
                                    Il sistema della IeFP fa parte del secondo ciclo di istruzione, assieme a Licei, Istituti Tecnici e Istituti Professionali, ed è di competenza regionale. Propone percorsi di durata compresa tra 3 e 4 anni,finalizzati al conseguimento – rispettivamente – di qualifiche e diplomi professionali, riconosciuti e spendibili a livello nazionale e comunitario.
                                    <div className='mt-4 section-fields-wrapper'>
                                        {proTrainingFields.map((subField, index) => (
                                            <Link
                                                key={index}
                                                to={subField.slug}
                                                rel='noreferrer'
                                                className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                                {subField.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="diploma-accordion-2">
                                <button className="accordion-button fs-4 rounded-0 collapsed px-3" type="button" data-bs-toggle="collapse" data-bs-target="#diploma-collapse-2" aria-expanded="false" aria-controls="diploma-collapse-2">
                                    Istituti tecnici e professionali
                                </button>
                            </h2>
                            <div id="diploma-collapse-2" className="accordion-collapse collapse" data-bs-parent="#diploma-accordion" role="region" aria-labelledby="diploma-accordion-2">
                                <div className="accordion-body">
                                    Fanno parte della scuola secondaria di secondo grado (assieme ai licei). Gli istituti tecnici propongono un numero limitato di ampi indirizzi, collegati a settori fondamentali per lo sviluppo economico e produttivo del paese. Gli istituti professionali formano gli studenti ad arti, mestieri e professioni strategici per l’economia del paese. Conseguito il diploma, è possibile proseguire verso indirizzi di studio successivi, come Lauree o ITS Academy.
                                    <div className='mt-4 section-fields-wrapper'>
                                        {technicalInstituteFields.map((subField, index) => (
                                            <Link
                                                key={index}
                                                to={subField.slug}
                                                rel='noreferrer'
                                                className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                                {subField.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="diploma-accordion-3">
                                <button className="accordion-button fs-4 rounded-0 collapsed px-3" type="button" data-bs-toggle="collapse" data-bs-target="#diploma-collapse-3" aria-expanded="false" aria-controls="diploma-collapse-3">
                                    Licei
                                </button>
                            </h2>
                            <div id="diploma-collapse-3" className="accordion-collapse collapse" data-bs-parent="#diploma-accordion" role="region" aria-labelledby="diploma-accordion-3">
                                <div className="accordion-body">
                                    Fanno parte della scuola secondaria di secondo grado (assieme agli Istituti Tecnici e Professionali). Gli indirizzi di studio liceali hanno durata generalmente di 5 anni e forniscono allo studente gli strumenti culturali e metodologici per una comprensione approfondita della realtà. Conseguito il diploma, è possibile proseguire verso indirizzi di studio successivi, come Lauree o ITS Academy.
                                    <div className='mt-4 section-fields-wrapper'>
                                        {highSchoolFields.map((subField, index) => (
                                            <Link
                                                key={index}
                                                to={subField.slug}
                                                rel='noreferrer'
                                                className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                                {subField.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5 pt-5'>
                <div className='col-12 bg-200'>
                    <h2 className='h3 fw-semibold text-black text-uppercase my-1'>ITS E LAUREE</h2>
                </div>
                <div className='col-12 mt-4 px-0'>
                    <div className="accordion" id="its-l-accordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="its-l-accordion-1">
                                <button className="accordion-button fs-4 rounded-0 collapsed px-3 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#its-l-collapse-1" aria-expanded="false" aria-controls="its-l-collapse-1">
                                    ITS Academy
                                </button>
                            </h2>
                            <div id="its-l-collapse-1" className="accordion-collapse collapse" data-bs-parent="#its-l-accordion" role="region" aria-labelledby="its-l-accordion-1">
                                <div className="accordion-body">
                                    Sono scuole di eccellenza ad alta specializzazione tecnologica che permettono di conseguire il titolo di tecnico superiore e che offrono percorsi in 6 aree tecnologiche. Accedono ai corsi i giovani e gli adulti in possesso di diploma di istruzione secondaria superiore e coloro che, in possesso di un diploma quadriennale di istruzione e formazione professionale, abbiano frequentato un corso annuale IFTS.
                                    <div className='mt-4 section-fields-wrapper'>
                                        {itsFields.map((subField, index) => (
                                            <Link
                                                key={index}
                                                to={subField.slug}
                                                rel='noreferrer'
                                                className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                                {subField.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="its-l-accordion-2">
                                <button className="accordion-button fs-4 rounded-0 collapsed px-3" type="button" data-bs-toggle="collapse" data-bs-target="#its-l-collapse-2" aria-expanded="false" aria-controls="its-l-collapse-2">
                                    Laurea
                                </button>
                            </h2>
                            <div id="its-l-collapse-2" className="accordion-collapse collapse" data-bs-parent="#its-l-accordion" role="region" aria-labelledby="its-l-accordion-2">
                                <div className="accordion-body">
                                    Il percorso universitario si suddivide in due cicli, ognuno con un diverso livello di formazione: al termine del primo si consegue il diploma di Laurea o Laurea triennale; con il completamento del secondo ciclo si ottiene la Laurea Magistrale o specialistica. Requisito d’accesso è il diploma di scuola secondaria superiore per le Lauree di 1° ciclo e per le Lauree Magistrali a ciclo unico. È necessario il titolo di Laurea per la Laurea Magistrale di 2° ciclo.
                                    <div className='mt-4 section-fields-wrapper'>
                                        {collegeDegreesFields.map((subField, index) => (
                                            <Link
                                                key={index}
                                                to={subField.slug}
                                                rel='noreferrer'
                                                className='btn btn-outline-primary rounded-pill text-start fw-semibold'>
                                                {subField.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PercorsiDiStudio;

