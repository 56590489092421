import React, { useState, useEffect } from 'react';

const HorizontalBarGraph = ({ data }) => {
    const sortedData = data.slice().sort((a, b) => b.difficulty - a.difficulty);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 991);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getWidth = (difficulty) => {
        if (difficulty < 1) return (isMobile ? '57%' : '40%');
        if (difficulty < 2) return (isMobile ? '67%' : '70%');
        return (isMobile ? '90%' : '85%');
    };

    const getDifficultyLabel = (difficulty) => {
        if (difficulty < 1) return 'basso';
        if (difficulty < 2) return 'medio';
        return 'alto';
    };

    const getBgColor = (difficulty) => {
        if (difficulty < 1) return '#004d99';
        if (difficulty < 2) return '#0060bf';
        return '#0073e5';
    };

    const lastDifficulty2Index = sortedData.map(item => item.difficulty).lastIndexOf(1);
    const lastDifficulty3Index = sortedData.map(item => item.difficulty).lastIndexOf(2);

    return (
        <div className="horizontal-bar-chart">
            {sortedData.map((item, index) => (
                <div key={index} className={`bar-container ${index === lastDifficulty2Index || index === lastDifficulty3Index ? 'last-category' : ''}`} title={`Le aziende richiedono un livello ${getDifficultyLabel(item.difficulty)} di ${item.name}`}>
                    <div className="difficulty-label">{getDifficultyLabel(item.difficulty)}</div>
                    <div className={`bar lvl-${getDifficultyLabel(item.difficulty)}`} style={{ width: getWidth(item.difficulty), background: getBgColor(item.difficulty) }} >
                        <span className="bar-label">{item.name}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HorizontalBarGraph;
