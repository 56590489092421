import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import "./styles/index.scss";
import initWpJs from './js/wp.jsx';
import { NavBarCollapsible, Dropdown, Collapse } from 'bootstrap-italia';
//import "bootstrap/dist/js/bootstrap.bundle.min.js";
//import "bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js"; 

const reactAppRoot = document.getElementById('react-root');

initWpJs();

if (reactAppRoot) {
  ReactDOM.createRoot(reactAppRoot).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )
}
