import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-4'>
                    <Link className='btn btn-primary w-100 text-uppercase fw-bolder' to="/react-app/dist/">Home</Link>
                </div>
                <div className='col-12 col-md-4'>
                    <Link className='btn btn-primary w-100 text-uppercase fw-bolder' to="/react-app/dist/professioni">Professioni</Link>
                </div>
                <div className='col-12 col-md-4'>
                    <Link className='btn btn-primary w-100 text-uppercase fw-bolder' to="/react-app/dist/percorsi-di-studio">Percorsi di Studio</Link>
                </div>
            </div>
        </div>
    );
}

export default Navigation;
