import React, { useState } from 'react';

const RegionStudy = () => {
    const regions = [
        {
            region: "Seleziona una regione",
            link: null
        },
        {
            region: "Abruzzo",
            link: "https://selfi.regione.abruzzo.it/menu_items/iefp"
        },
        {
            region: "Basilicata",
            link: "https://www.regione.basilicata.it/giunta/site/giunta/department.jsp?dep=100056&area=109331&level=0"
        },
        {
            region: "Calabria",
            link: "https://www.regione.calabria.it/website/organizzazione/dipartimento2/subsite/settori_presidenza/formazione/formazione_professionale/"
        },
        {
            region: "Campania",
            link: "https://lavoro.regione.campania.it/index.php/per-i-datori-di-lavoro/istruzione/norme-bandi-regolamenti/istruzione-e-formazione-professionale-iefp-2#:~:text=La%20Regione%20Campania%20offre%20la,Regione%20e%20Ufficio%20Scolastico%20Regionale"
        },
        {
            region: "Emilia Romagna",
            link: "https://formazionelavoro.regione.emilia-romagna.it/iefp"
        },
        {
            region: "Friuli Venezia Giulia",
            link: "https://effepi.fvg.it/sedi/"
        },
        {
            region: "Lazio",
            link: "https://www.regione.lazio.it/cittadini/formazione/offerta-formativa?formazione_finanziata=&settore=&Ragione_Sociale=&provincia=&comune=&Testo_Libero=&action=search&submit=Ricerca"
        },
        {
            region: "Liguria",
            link: "https://www.regione.liguria.it/component/publiccompetitions/document/39623.html?view=document&id=39623:elenco-recapiti-enti-formazione-percorsi-iefp&Itemid=7042"
        },
        {
            region: "Lombardia",
            link: "https://www.regione.lombardia.it/wps/portal/istituzionale/HP/formazioneprofessionale/qualifiche"
        },
        {
            region: "Marche",
            link: "https://www.regione.marche.it/Regione-Utile/Istruzione-Formazione-e-Diritto-allo-Studio/Istruzione-e-formazione-professionale#Introduzione"
        },
        {
            region: "Molise",
            link: "https://www.regione.molise.it/flex/cm/pages/ServeBLOB.php/L/IT/IDPagina/7049"
        },
        {
            region: "Piemonte",
            link: "https://www.regione.piemonte.it/web/temi/istruzione-formazione-lavoro/formazione-professionale/opportunita-formative/corsi-per-giovani-14-24-anni"
        },
        {
            region: "Puglia",
            link: "https://www.studioinpuglia.regione.puglia.it/wp-content/uploads/1.1.1-IS-intese-accreditate-REV-21-22.pdf"
        },
        {
            region: "Sardegna",
            link: "https://www.regione.sardegna.it/argomenti/cultura/formazione.html"
        },
        {
            region: "Sicilia",
            link: "https://www.regione.sicilia.it/istituzioni/regione/strutture-regionali/assessorato-istruzione-formazione-professionale/dipartimento-formazione-professionale/corsi-di-formazione/Elenco-delle-commissioni-di-esami"
        },
        {
            region: "Toscana",
            link: "https://www.regione.toscana.it/istruzione-e-formazione-professionale"
        },
        {
            region: "Umbria",
            link: "https://www.regione.umbria.it/istruzione/istruzione-e-formazione-professionale"
        },
        {
            region: "Val d'Aosta",
            link: "http://www.progettoformazione.org/iefp"
        },
        {
            region: "Veneto",
            link: "https://www.cliclavoroveneto.it/cerca-corsi-di-formazione"
        },
        {
            region: "Provincia autonoma di Trento",
            link: "https://www.vivoscuola.it/Scuole"
        },
        {
            region: "Provincia autonoma di Bolzano",
            link: "https://www.provincia.bz.it/formazione-lingue/orientamento-scolastico-universitario-professionale/istruzione-formazione-professionale.asp"
        },
    ];

    const [selectedRegion, setSelectedRegion] = useState(regions[0]);

    const handleRegionChange = (e) => {
        const selectedRegion = regions.find(region => region.region === e.target.value);
        setSelectedRegion(selectedRegion);
    };

    return (
        <div className='container-fluid mb-5'>
            <div className='row'>
                <div className='col-12 col-lg-6 py-5 px-3 px-lg-5 bg-primary d-flex flex-column align-items-center justify-content-between'>
                    <h3 className='h4 text-start mb-5 text-white fw-normal lh-base pt-5'>
                        Gli istituti di <span className='fw-700'>Istruzione e Formazioni Professionale</span> sono differenti per ogni regione.
                        <br /><br />
                        Selezionando la tua regione possiamo rimandarti al sito in cui trovare informazioni più specifiche.
                    </h3>
                    <select
                        id="change-region-secondary"
                        name="regioni-italia"
                        title='Scegli una regione'
                        className="border-primary border rounded-0 form-select-lg fw-semibold w-100 mb-5"
                        value={selectedRegion.region}
                        onChange={handleRegionChange}
                    >
                        {regions.map((region, index) => (
                            <option key={index} value={region.region}>{region.region}</option>
                        ))}
                    </select>
                    {selectedRegion.link && (
                        <a href={selectedRegion.link}
                            target='_blank'
                            rel='noreferrer'
                            className='btn btn-white rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm mb-5'>
                            Visita il sito della regione
                        </a>
                    )}
                </div>
                <div className='col-12 col-lg-6 py-5 px-3 px-lg-5 bg-100 d-flex flex-column align-items-center justify-content-between'>
                    <h3 className='h4 text-start mb-5 text-black fw-normal lh-base pt-5'>
                        Oppure utilizza <span className='fw-700'>Scuola in Chiaro</span> per cercare quali istituti sono vicino a te
                        <br /><br />
                        Sulla piattaforma <span className='fw-700'>Scuola in Chiaro</span> puoi fare una ricerca per trovare le scuole più vicine a dove abiti e trovare le loro principali informazioni, fra cui i loro contatti
                    </h3>
                    <a href='https://cercalatuascuola.istruzione.it/cercalatuascuola/' target='_blank' rel='noreferrer' className='btn btn-primary rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm mb-5'>Visita il sito</a>
                </div>
            </div>
        </div>
    );
};

export default RegionStudy;