import React, { useEffect, useState } from 'react';


const TrendBarGraph = ({ startXValue, endXValue, startValue, endValue, percent, description, united }) => {
    const title = description ? description : 'Grafico a barre';

    return (
        <div className={`bars-graph ${united ? "united-bars" : ""} ${percent > 0 ? 'to-up' : (percent == 0 ? 'plain' : 'to-down')}`} title={title}>
            <div className='bar first'>
                <div className='year'>
                    {startXValue}
                </div>
                <div className='detail d-flex flex-column align-items-center jusfity-content-end fw-semibold'>
                    <span className=''>
                        {startValue.toLocaleString()}  assunzioni
                    </span>
                </div>
            </div>
            {/* numero di barre intermedie */}
            {Array.from({ length: 3 }).map((_, index) => (
                <div className='bar' key={index}></div>
            ))}
            <div className='bar last'>
                <div className='percent'>
                    {`${percent > 0 ? '+' : ''}`}{percent}%
                </div>
                <div className='detail d-flex flex-column align-items-center jusfity-content-end fw-semibold'>
                    <span className=''>
                        {endValue.toLocaleString()}  assunzioni
                    </span>
                </div>
                <span className='year'>
                    {endXValue}
                </span>
            </div>
        </div>
    );
}

export default TrendBarGraph;

