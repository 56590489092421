import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OrientaCta from '../components/OrientaCta.jsx';
import Jobs from '../../data/jobs.json';

const DettaglioSector = ({ selectedSector }) => {
    const [selectedOrder, setSelectedOrder] = useState(0);
    const [retrivedJobs, setRetrivedJobs] = useState([]);
    const [jobsWithTrend, setJobsWithTrend] = useState([]);
    const [sortedJobsWithTrend, setSortedJobsWithTrend] = useState([]);
    const [mySector, setMySector] = useState(selectedSector);
    const [selectedRegion, setSelectedRegion] = useState(20);

    useEffect(() => {
        setMySector(selectedSector);
    }, [selectedSector]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const accordionTrg = document.querySelector('a.custom-info-accordion-trg');
        const accordion = document.querySelector('div.custom-info-accordion');
        if (accordionTrg && accordion) {
            const handleClick = (e) => {
                e.preventDefault();
                accordion.classList.toggle('open');
                window.scrollTo(0, 0);
            };
            accordionTrg.addEventListener('click', handleClick);
            return () => {
                accordionTrg.removeEventListener('click', handleClick);
            };
        }
    }, []);



    /* 
     *  JOB FILTER BY SECTOR
     */
    useEffect(() => {
        const retrived = [];
        Jobs.forEach((job) => {
            const jobSectors = job.sectors.filter((s) => s.isExposed);
            if (mySector && mySector.id) {
                if (jobSectors.find((s) => s.sectorId === mySector.id)) {
                    retrived.push(job);
                }
            }
        });
        const limited = retrived.slice(0, 18);
        setRetrivedJobs(limited);
    }, [mySector]);




    /* 
     *  JOB TREND and DIFFICULTY DATA
     */

    function getTrendData(region, sectorId, job) {
        const jobSectors = job ? job.sectors : Jobs;
        const sectors = jobSectors.filter((s) => sectorId ? s.sectorId === sectorId : true);
        const trendReferenceYear = 2023;
        return {
            past: {
                year: trendReferenceYear - 4,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.pastEntries[region]) {
                        previousValue += Number(currentValue.pastEntries[region]);
                    }
                    return previousValue;
                }, 0) / 10) * 10
            },
            present: {
                year: trendReferenceYear,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.entries[region]) {
                        previousValue += Number(currentValue.entries[region]);
                    }
                    return previousValue;
                }, 0) / 10) * 10
            }
        };
    }


    function getDifficultyPercentage(job) {
        let region = selectedRegion;
        let sectorId = ""; //lasciare vuoto altrimenti NaN
        let diffTot = 0;
        let entTot = 0;
        job.sectors.filter((s) => sectorId ? s.sectorId === sectorId : true).forEach((s) => {
            diffTot += Number(s.findingDifficulty[region]);
            entTot += Number(s.entries[region]);
        });
        if (entTot >= 0) {
            return Math.floor(diffTot / entTot * 100);
        }
        return -1;
    }


    useEffect(() => {
        const jobsWithTrend = retrivedJobs.map((job) => {
            const trendData = getTrendData(selectedRegion, "", job);
            const percent = ((trendData.present.value - trendData.past.value) / trendData.past.value) * 100;
            const max100ifInfinity = percent === Infinity ? 100 : percent;
            const endValue = trendData.present.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            const endDate = trendData.present.year;
            const difficultyPercentage = getDifficultyPercentage(job);
            console.log(job.jobName, (Math.round(max100ifInfinity * 100) / 100));
            return {
                ...job,
                trendPercent: Math.round(max100ifInfinity * 100) / 100,
                trendEndValue: endValue,
                trendEndYear: endDate,
                difficultyPercentage: difficultyPercentage,
                difficulty: difficultyPercentage < 30 ? 'bassa' : difficultyPercentage < 70 ? 'media' : difficultyPercentage < 80 ? 'medio-alta' : 'alta'
            };
        });
        setJobsWithTrend(jobsWithTrend);
    }, [mySector, retrivedJobs, selectedRegion]);

    useEffect(() => {
        const parseTrendEndValue = (value) => parseInt(value.replace(/\./g, ''), 10);
        const sortedJobs = [...jobsWithTrend].sort((a, b) => {
            switch (selectedOrder) {
                case 0:
                    return a.trendPercent - b.trendPercent;
                case 1:
                    return b.trendPercent - a.trendPercent;
                case 2:
                    return parseTrendEndValue(a.trendEndValue) - parseTrendEndValue(b.trendEndValue);
                case 3:
                    return parseTrendEndValue(b.trendEndValue) - parseTrendEndValue(a.trendEndValue);
                case 4:
                    return a.difficultyPercentage - b.difficultyPercentage;
                case 5:
                    return b.difficultyPercentage - a.difficultyPercentage;
                default:
                    return 0;
            }
        });
        setSortedJobsWithTrend(sortedJobs);
    }, [selectedOrder, jobsWithTrend]);

    return (
        <>
            <div className='bg-primary page-title-row top-0 z-3'>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-10">
                            <h1 className="mb-0 text-white">{selectedSector.label}</h1>
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-end'>
                            <a href='#' className='border border-white custom-info-accordion-trg rouded-50 rounded-circle text-decoration-none text-white' title="Informazioni sui percorsi">
                                i
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container custom-info-accordion'>
                <div className='row'>
                    <div className='col-12'>
                        <p className='alert alert-info fw-normal mt-5'>
                            All'interno di questa tabella puoi trovare le professioni che maggiormente caratterizzano il settore, definiti sulla base dei dati delle imprese del sistema camerale italiano, raccolti dal Sistema Informativo Excelsior.
                        </p>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row mb-4'>
                    <div className='col-12 my-5'>
                        <p className='fs-4 lh-base'>
                            {selectedSector.description}
                        </p>
                    </div>
                </div>
            </div>
            <div className='bg-100'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-8 col-xl-9'>
                            <h2 className='h3 fw-bold mb-4 mb-md-0'>Area di interesse</h2>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center justify-content-end'>
                            <select
                                id="change-region"
                                defaultValue={20}
                                name="regioni-italia"
                                title='Scegli una regione'
                                onChange={(e) => setSelectedRegion(Number(e.target.value))}
                                className="border-primary border rounded-3 form-select fw-semibold w-100"
                            >
                                <option value="0">Piemonte</option>
                                <option value="1">Valle d'Aosta</option>
                                <option value="2">Lombardia</option>
                                <option value="3">Trentino-Alto Adige</option>
                                <option value="4">Veneto</option>
                                <option value="5">Friuli-Venezia Giulia</option>
                                <option value="6">Liguria</option>
                                <option value="7">Emilia-Romagna</option>
                                <option value="8">Toscana</option>
                                <option value="9">Umbria</option>
                                <option value="10">Marche</option>
                                <option value="11">Lazio</option>
                                <option value="12">Abruzzo</option>
                                <option value="13">Molise</option>
                                <option value="14">Campania</option>
                                <option value="15">Puglia</option>
                                <option value="16">Basilicata</option>
                                <option value="17">Calabria</option>
                                <option value="18">Sicilia</option>
                                <option value="19">Sardegna</option>
                                <option value="20">Tutta Italia</option>
                            </select>
                        </div>
                    </div>
                    {selectedRegion === 20 && (
                        <div className='row mt-4'>
                            <div className="col-12">
                                <p className="fs-5 alert alert-info fw-semibold mb-0">
                                    Selezione una regione per visualizzare le opportunità formative specifiche
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="container pt-5">
                <div className='row mt-5 pt-lg-3'>
                    <div className='col-12 d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-end'>
                        <p className='fw-bold text-uppercase mb-4 me-lg-3 mb-lg-0'>Ordina per</p>
                        <select
                            id="change-order"
                            defaultValue={0}
                            name="order-by"
                            title='Ordina lista dei lavori per:'
                            onChange={(e) => setSelectedOrder(Number(e.target.value))}
                            className="border-primary border rounded-3 form-select fw-semibold"
                            style={{ width: "fit-content", maxWidth: "95%" }}
                        >
                            {/* <option value="0">Difficoltà di reperimento ALTA</option>
                            <option value="1">Difficoltà di reperimento BASSA</option> */}
                            <option value="0">Trend occupazionale - CRESCENTE</option>
                            <option value="1">Trend occupazionale - DECRESCENTE</option>
                            <option value="2">Assunzioni - CRESCENTE</option>
                            <option value="3">Assunzioni - DECRESCENTE</option>
                            <option value="4">Difficoltà di reperimento - CRESCENTE</option>
                            <option value="5">Difficoltà di reperimento - DECRESCENTE</option>
                        </select>
                    </div>
                </div>
            </div>
            {sortedJobsWithTrend.length > 0 && (
                <div className="container pb-5 my-5 mt-lg-4">
                    <div className={`row justify-content-start d-flex align-items-stretch pt-5 pt-lg-0`}>
                        {sortedJobsWithTrend.map((job, index) => (
                            <div className='col-12 col-lg-4 mb-5 mb-lg-4 mt-lg-4' key={index}>
                                <div className='card border-0 bg-100 h-100 rounded-3' title={job.jobName}>
                                    <div className='card-body d-flex flex-column justify-content-between'>
                                        <div className='main'>
                                            <h3 className='h5 fw-bold mb-4 ellipsis'>{job.jobName}</h3>
                                        </div>
                                        <div>
                                            <div className='info'>
                                                <p className='mb-4'>
                                                    Difficoltà di reperimento: <span className='fw-bold text-uppercase'>{job.difficulty}</span>
                                                    <br />
                                                    {!isNaN(job.trendPercent) && (
                                                        <>
                                                            Trend occupazionale: <span className='fw-bold'>{`${job.trendPercent > 0 ? '+' : ''}`}{job.trendPercent}%</span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {job.trendEndValue && job.trendEndYear && (
                                                        <span>  {`Assunzioni nell'anno ${job.trendEndYear}:`} <span className='fw-bold'>{`${job.trendEndValue}`}</span></span>
                                                    )}
                                                </p>
                                            </div>
                                            <Link
                                                key={index}
                                                to={`/professioni/${job.slug}`}
                                                className='text-uppercase text-decoration-none text-primary fw-semibold'>
                                                Visualizza professione
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='h4 text-center text-uppercase'>Scopri tutte le altre professioni</h2>
                    </div>
                    <div className='col-12 pb-5 pt-4 d-flex align-items-center justify-content-center'>
                        <Link
                            to='/professioni'
                            className='text-decoration-none fw-semibold text-uppercase btn btn-primary rounded-0'
                        >
                            cerca le professioni
                        </Link>
                    </div>
                </div>
            </div>
            <OrientaCta />
        </>
    );
};

export default DettaglioSector;
