import Swiper from 'swiper/bundle';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import Masonry from 'masonry-layout';

export default function initWpJs() {
    console.log('WP JS Mounted');

    const swiper = new Swiper('.wp-card-swiper', {
        modules: [Navigation, Pagination, A11y],
        speed: 400,
        spaceBetween: 50,
        slidesPerView: 1,
        navigation: {
            nextEl: '.wp-card-swiper .swiper-button-next',
            prevEl: '.wp-card-swiper .swiper-button-prev',
        },
        pagination: {
            el: '.wp-card-swiper .swiper-pagination',
            clickable: true,
        },
    });

    const verticalSwiper = new Swiper('.wp-vertical-card-swiper', {
        modules: [Navigation, Pagination, A11y],
        speed: 400,
        spaceBetween: 0,
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
            nextEl: '.wp-vertical-card-swiper .swiper-button-next',
            prevEl: '.wp-vertical-card-swiper .swiper-button-prev',
        },
        pagination: {
            el: '.wp-vertical-card-swiper .swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 0,
                loop: true,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
            },
        },
    });

    // function removeButtons() {
    //     if (window.innerWidth > 1024) {
    //         if (verticalSwiper.slides.length < 3) {
    //             document.querySelector('.wp-vertical-card-swiper .swiper-button-prev').classList.remove('d-lg-block');
    //             document.querySelector('.wp-vertical-card-swiper .swiper-button-next').classList.remove('d-lg-block');
    //         } else {
    //             document.querySelector('.wp-vertical-card-swiper .swiper-button-prev').classList.add('d-lg-block');
    //             document.querySelector('.wp-vertical-card-swiper .swiper-button-next').classList.add('d-lg-block');
    //         }
    //     }
    // }
    // removeButtons();
    // window.addEventListener('resize', () => {
    //     removeButtons();
    // });




    const docSmallCardSwiper = new Swiper('.wp-doc-smallcard-swiper', {
        modules: [Navigation, Pagination, A11y],
        speed: 400,
        spaceBetween: 0,
        slidesPerView: 1.2,
        autoHeight: true,
        navigation: {
            nextEl: '.wp-doc-smallcard-swiper .swiper-button-next',
            prevEl: '.wp-doc-smallcard-swiper .swiper-button-prev',
        },
        pagination: {
            el: '.wp-doc-smallcard-swiper .swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 0,
                loop: true,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
            },
        },
    });
}