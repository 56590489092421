import React, { useEffect, useState } from 'react';

const BigBarGraph = ({ labels, values }) => {
    const [elements, setElements] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 991);
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 991);
        });
    }, []);

    useEffect(() => {
        setElements(values.map((value, index) => {
            const cutProportion = isMobile ? 0 : 5;
            const dimesion = (value !== null && value !== undefined) ? (value + cutProportion) : cutProportion;

            return (
                <div key={index} className="bar" >
                    <span
                        className="value"
                        style={isMobile ? { width: `${dimesion}%` } : { height: `${dimesion}%` }}
                        title={`${value}% richiedono lo studio ${labels[index]}`}
                    >
                        {value}%
                    </span>
                    <span className="label">
                        {labels[index]}
                    </span>
                </div>
            );
        }));
    }, [values, isMobile, labels]);

    return (
        <div className="big-bar-chart pt-5 pb-4">
            <div className="bars">
                {elements}
            </div>
        </div>
    );
}

export default BigBarGraph;

